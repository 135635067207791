import * as React from "react";
import Plx from "react-plx";
import CultureRainFlip from "../images/2.0/culture-rain-flip.svg";
import CultureRainBase from "../images/2.0/culture-rain.svg";

const CultureRain = (props) => {
  const cultureRef = React.useRef(null);

  let comingSoonData = [
    {
      start:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: -50,
          endValue: -50,
          property: "translateX",
          unit: "%",
        },
      ],
    },
    {
      start:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: 0,
          endValue: -1,
          property: "scaleY",
        },
      ],
    },
    {
      start:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) +
        (cultureRef.current ? cultureRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: props.orientation == "portrait" ? 72 : 35,
          endValue: props.orientation == "portrait" ? 48 : 26.5,
          property: "top",
          unit: props.orientation == "portrait" ? "px" : "vh",
        },
      ],
    },
  ];

  React.useEffect(() => {
    props.total(cultureRef.current ? cultureRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={cultureRef} className="culturerain crsmaller rain">
      <h2>WE MAKE CULTURE RAIN</h2>
      <h3 style={{left:'auto',transform:'scaleY(-1)',top:((props.orientation == "portrait" ? '48' : '26.5')+(props.orientation == "portrait" ? "px" : "vh")),opacity:1}}>WE MAKE CULTURE RAIN</h3>
    </div>
  );
};

export default CultureRain;
