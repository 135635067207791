import * as React from "react";
import Plx from "react-plx";
import logoImg from "../images/2.0/CR_logoclaimmask2700.svg";
const isBrowser = typeof window !== "undefined";
// markup
const Intro = (props) => {
  const [beigeHeight, setBeigeHeight] = React.useState(100);
  const [display, setDisplay] = React.useState("block");

  let introLogoData = [
    {
      start: props.height - props.height / 2,
      end: props.height + (props.height - props.height / 2),
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 1,
          endValue: 100,
          property: "scale",
        },
      ],
    },
    {
      start: props.height + props.height / 3,
      end: props.height + props.height / 2,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: "opacity",
        },
      ],
    },
  ];
  let introBeigeData = [
    {
      start: 0,
      end: props.height / 2,
      easing: [0.42, 0.0, 1.0, 1.0],
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: "height",
          unit: "%",
        },
      ],
    },
  ];

  const updateScrollPosition = () => {
    let beigeRelativeHeight = Math.ceil(
      ((isBrowser ? window.scrollY : 800) / props.height) * 100
    );
    if (beigeRelativeHeight < 100) {
      setBeigeHeight(100 - beigeRelativeHeight);
    } else {
      setBeigeHeight(0);
    }
    if (props.landing) {
      if (
        (isBrowser ? window.scrollY : 800) >
        props.height * 2 - props.height / 2
      ) {
        setDisplay("none");
      } else {
        setDisplay("block");
      }
    }
  };
  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });
  return (
    <div
      className="intro"
      style={{ height: props.height * 1.5, display: display }}
    >
      <Plx
        style={{ height: `${props.height}px` }}
        className="intro_logo"
        parallaxData={introLogoData}
      >
        <svg
          id="logoMobile"
          preserveAspectRatio="xMidYMid slice"
          fill="black"
          fillOpacity="1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 841.89 1000"
        >
          <path d="M413.51,501.37a9,9,0,0,0,8.87,9,8.44,8.44,0,0,0,2.2-.23l-8.67-14.9A9.1,9.1,0,0,0,413.51,501.37Z" />
          <polygon points="484.12 493.7 480.7 504.25 484.12 504.25 484.12 504.25 487.54 504.25 484.12 493.69 484.12 493.7" />
          <path d="M456.92,491.25h-6.48v8h6.48c3.25,0,4.8-1.28,4.8-4S460.21,491.25,456.92,491.25Z" />
          <path d="M.19.16V999.8H841.8V.16Zm325,494-3-2.19c-5.73-1.21-9.69-.16-12,2.41-1.6,1.77-2.07,4.45-2.07,6.86s.47,5.09,2.07,6.87c2.31,2.56,6.27,3.61,12,2.41l3-2.2v7.18a45.76,45.76,0,0,1-7.65.53c-10.48,0-15.05-6.83-15.05-14.79h0c0-8,4.57-14.79,15.05-14.79a45.76,45.76,0,0,1,7.65.53Zm27.7-4.39V505c0,5.2-.57,7-2.69,9.06s-5.38,2.87-9,2.87-6.45-.94-8.49-2.87S330,510.18,330,505V489.81l-1.34-3.31h8.15l-1.35,3.31v14.9c0,3.77.22,4.84,1.22,5.83s2.6,1.48,4.77,1.48,4-.58,4.93-1.71,1-2,1-5.6V489.8L346,486.5h8.16Zm25.79,26.28H356.92l1.51-2.28V489.65l-1.37-3.15h8.22l-1.37,3.16v21h12.41l2.32-1.37ZM398.5,491.9h-7.63v21.9l1.35,2.26h-8.15l1.34-2.26V491.9h-7.67l-2.21,1.37v-6.79H400.7v6.8Zm38.33,29h-8.31l.8-2.63-2-3.46a14.41,14.41,0,0,1-4.9.83,14.25,14.25,0,0,1-9.19-25L408,481.71h23.82v6.66l-2.56-1.53H417l12,20.67,2.72-4.31h5.62l-5.62,9C434.69,517.18,436.83,520.85,436.83,520.85Zm.63-41.18h-1v2.7h-.57v-2.7h-1v-.52h2.55Zm3.88,2.7h-.54v-2.68h0l-1,2.68h-.4l-1-2.68h0v2.68h-.55v-3.22h.87l.87,2.26.87-2.26h.86Zm18.07,33.72.8-2.7L455.37,504h-4.93v9.85l1.37,2.26h-8.35l1.38-2.26V488.76l-1.38-2.26h13.29c4.22,0,6.4.67,8.17,2.49a9.08,9.08,0,0,1,2.36,6.3c0,4.12-2.22,7-6.27,8.07l4.8,9.27,3,3.46Zm29.87,0,.84-3.37-1.38-4.12H479.5l-1.38,4.12.85,3.37H470.3l2.13-3.28,8-24-1.14-2.26h9.6l-1.14,2.26,8,24,2.13,3.28Zm20.33,0h-9.06l1.5-2.27V488.78l-1.5-2.27h9.06l-1.49,2.27v25.06Zm29.83,0h-9l1-2.27-10.82-18.63v18.63l1.38,2.27h-7.83l1.38-2.27V488.78l-1.38-2.27h9.92l-1,2.27L533,505.72V488.78l-1.38-2.27h7.82l-1.37,2.27v25.06Z" />
        </svg>
        <svg
          version="1.1"
          id="logoDesktop"
          xmlns="http://www.w3.org/2000/svg"
          style={{ enableBackground: "new 0 0 318.6 199.2" }}
          x="0px"
          y="0px"
          viewBox="0 0 2700 1080"
          preserveAspectRatio="xMidYMid slice"
          fill="black"
          fillOpacity="1"
          data-name="Layer 1"
        >
          <path d="M1692.7,505.3l-18.5,57.5h18.5h18.4L1692.7,505.3z" />
          <path d="M1571.2,514c0-14.6-8.1-21.7-26-21.7H1510v43.4h35.2C1562.5,535.7,1571.2,528.6,1571.2,514z" />
          <path
            d="M1309.9,547c0,26.6,21.7,48.3,48.3,48.8c3.8,0,8.1-0.5,11.9-1.1l-47.2-80.8C1314.2,522.7,1309.9,534.6,1309.9,547
	L1309.9,547z"
          />
          <g>
            <path
              d="M1387.3,696.8c0.2-0.3,0.3-0.7,0.3-1.3c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.7-0.3-1.1-0.3
		c-0.4,0-0.8-0.1-1.2-0.1h-2.6v4.6h2.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3C1386.8,697.3,1387.1,697.1,1387.3,696.8z"
            />
            <path
              d="M1371.1,697c0.5-0.4,0.8-0.9,0.8-1.5c0-0.7-0.3-1.3-0.8-1.6c-0.5-0.4-1.4-0.5-2.5-0.5h-2.5v4.3h2.8
		C1369.8,697.6,1370.6,697.4,1371.1,697z"
            />
            <path
              d="M1371.7,700.7c-0.6-0.4-1.5-0.5-2.7-0.5h-2.9v4.9h2.8c0.4,0,0.8,0,1.2-0.1c0.4,0,0.8-0.2,1.2-0.3c0.4-0.2,0.7-0.4,0.9-0.8
		c0.2-0.3,0.4-0.8,0.4-1.3C1372.6,701.7,1372.3,701,1371.7,700.7z"
            />
            <polygon points="1397.8,701.2 1403.1,701.2 1400.4,694.2 	" />
            <path
              d="M1656.8,697c0.5-0.4,0.8-0.9,0.8-1.5c0-0.7-0.3-1.3-0.8-1.6c-0.5-0.4-1.4-0.5-2.5-0.5h-2.5v4.3h2.8
		C1655.6,697.6,1656.3,697.4,1656.8,697z"
            />
            <path
              d="M1440.5,694.7c-0.6-0.5-1.2-0.8-2-1c-0.8-0.2-1.6-0.3-2.6-0.3h-2.8v11.5h2.3c0.9,0,1.8-0.1,2.7-0.3c0.8-0.2,1.6-0.6,2.2-1
		s1.1-1,1.5-1.8c0.4-0.7,0.5-1.6,0.5-2.7c0-1-0.2-1.9-0.5-2.6S1441.1,695.2,1440.5,694.7z"
            />
            <path
              d="M1463.5,697c0.5-0.4,0.8-0.9,0.8-1.5c0-0.7-0.3-1.3-0.8-1.6c-0.5-0.4-1.4-0.5-2.5-0.5h-2.5v4.3h2.8
		C1462.3,697.6,1463,697.4,1463.5,697z"
            />
            <path
              d="M1657.4,700.7c-0.6-0.4-1.5-0.5-2.7-0.5h-2.9v4.9h2.8c0.4,0,0.8,0,1.2-0.1c0.4,0,0.8-0.2,1.2-0.3c0.4-0.2,0.7-0.4,0.9-0.8
		c0.2-0.3,0.4-0.8,0.4-1.3C1658.3,701.7,1658,701,1657.4,700.7z"
            />
            <path
              d="M1107.8,696.8c0.2-0.3,0.3-0.7,0.3-1.3c0-0.5-0.1-0.9-0.3-1.2s-0.5-0.5-0.8-0.7c-0.3-0.2-0.7-0.3-1.1-0.3
		c-0.4,0-0.8-0.1-1.2-0.1h-2.6v4.6h2.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3C1107.3,697.3,1107.6,697.1,1107.8,696.8z"
            />
            <path
              d="M0,0v1080h2700V0H0z M1472,466.2h72.1c22.8,0,34.7,3.8,44.5,13.6c8.7,9.2,13,21.7,13,34.2c1.1,21.1-13.6,39.6-34.2,43.9
		l26,50.4l16.3,19l-51,0.5l4.3-14.6l-26-51h-27.1l0-1.1v53.1l7.6,12.5H1472l7.6-12.5V478.7L1472,466.2z M1424.9,426.7h0.5h13.6v2.7
		h-5.4V444h-3.3v-14.6h-5.4V426.7z M830.6,508l-16.3-11.9c-30.9-6.5-52.6-1.1-65.1,13c-8.7,9.8-11.4,24.4-11.4,37.4
		s2.7,27.7,11.4,37.4c12.5,14.1,34.2,19.5,65.1,13l16.3-11.9v39c-13.6,2.2-27.7,3.3-41.7,2.7c-56.9,0-81.3-36.9-81.3-80.2
		s24.9-80.2,81.3-80.2c14.1,0,27.7,0.5,41.7,2.7V508z M980.8,484.2v82.4c0,28.2-3.3,38-14.6,49.3c-11.4,11.4-29.3,15.7-48.8,15.7
		s-35.2-4.9-46.1-15.7c-10.8-10.8-14.6-21.1-14.6-49.3v-82.4l-7-17.9h43.9l-7,17.9V565c0,20.6,1.1,26,6.5,31.4s14.1,8.1,26,8.1
		s21.7-3.3,26.6-9.2s5.4-10.8,5.4-30.4v-80.8l-7-17.9h44.5L980.8,484.2z M1003.6,466.3h44.5l-7.6,17.3v113.9h67.2l12.5-7.6v36.9
		H1003l8.1-12.5V483.6L1003.6,466.3z M1037,693.4h-5.2v14.3h-3v-14.3h-5.2v-2.7h13.4V693.4z M1053,707.6h-3v-7.5h-7.9v7.5h-3v-17h3
		v6.8h7.9v-6.8h3V707.6z M1068.5,707.6h-11.7v-17h11.3v2.7h-8.2v4.2h7.8v2.7h-7.8v4.6h8.7V707.6z M1089.6,693.4h-7.9v4.5h7.5v2.7
		h-7.5v7.1h-3v-17h11V693.4z M1095.3,707.6h-3v-17h3V707.6z M1108.2,707.6l-3.9-7.2h-2.2v7.2h-3v-17h5.9c0.8,0,1.6,0.1,2.4,0.2
		c0.8,0.2,1.4,0.4,2,0.8c0.6,0.4,1.1,0.9,1.4,1.5s0.5,1.4,0.5,2.4c0,1.2-0.3,2.3-1,3.1c-0.7,0.8-1.7,1.3-2.9,1.5l4.5,7.5H1108.2z
		 M1124.3,705c-0.3,0.7-0.8,1.2-1.4,1.7c-0.6,0.4-1.2,0.8-2,1c-0.8,0.2-1.5,0.3-2.4,0.3c-1.1,0-2.1-0.2-3-0.5
		c-1-0.4-1.8-0.9-2.5-1.8l2.3-2.2c0.4,0.6,0.9,1,1.5,1.3c0.6,0.3,1.2,0.5,1.9,0.5c0.4,0,0.7,0,1.1-0.1c0.4-0.1,0.7-0.2,1-0.4
		c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.3-0.6,0.3-1c0-0.6-0.2-1.1-0.6-1.4s-0.9-0.6-1.5-0.9c-0.6-0.2-1.2-0.5-1.9-0.7
		c-0.7-0.2-1.4-0.5-1.9-0.9c-0.6-0.4-1.1-0.8-1.5-1.4s-0.6-1.4-0.6-2.4c0-0.9,0.2-1.6,0.5-2.3c0.4-0.6,0.8-1.2,1.4-1.6
		s1.3-0.7,2-0.9c0.8-0.2,1.5-0.3,2.3-0.3c0.9,0,1.8,0.1,2.6,0.4c0.8,0.3,1.6,0.7,2.3,1.3l-2.2,2.3c-0.3-0.5-0.7-0.8-1.3-1
		s-1.1-0.3-1.7-0.3c-0.4,0-0.7,0-1,0.1c-0.3,0.1-0.6,0.2-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1
		c0,0.6,0.2,1,0.6,1.3s0.9,0.6,1.5,0.8c0.6,0.2,1.2,0.4,1.9,0.6c0.7,0.2,1.4,0.5,1.9,0.9c0.6,0.4,1.1,0.9,1.5,1.5
		c0.4,0.6,0.6,1.4,0.6,2.4C1124.8,703.6,1124.6,704.4,1124.3,705z M1139.7,693.4h-5.2v14.3h-3v-14.3h-5.2v-2.7h13.4V693.4z
		 M1159.1,707.6h-10.2v-17h3v14.3h7.2V707.6z M1174.9,701.4c0,1-0.2,1.9-0.5,2.8c-0.4,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1-2.2,1.3
		c-0.8,0.3-1.7,0.5-2.7,0.5c-1,0-1.9-0.2-2.7-0.5c-0.8-0.3-1.6-0.8-2.2-1.3s-1.1-1.3-1.4-2.1s-0.5-1.7-0.5-2.8v-10.8h3v10.7
		c0,0.4,0.1,0.9,0.2,1.3c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,1.1,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4
		c0.5-0.2,0.9-0.6,1.2-0.9c0.3-0.4,0.5-0.8,0.6-1.3c0.1-0.5,0.2-0.9,0.2-1.3v-10.7h3V701.4z M1189.3,707.6l-4.5-7.2l-4.5,7.2h-3.6
		l6-8.9l-5.7-8.1h3.8l4,6.3l4-6.3h3.7l-5.6,8.1l6.2,8.9H1189.3z M1149.9,626.7l7-12.5V495.5h-41.7l-11.9,7.6v-36.9h136.6v36.9
		l-11.9-7.6h-41.2v118.7l7,12.5H1149.9z M1208.7,701.4c0,1-0.2,1.9-0.5,2.8c-0.4,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.3,1-2.2,1.3
		c-0.8,0.3-1.7,0.5-2.7,0.5c-1,0-1.9-0.2-2.7-0.5c-0.8-0.3-1.6-0.8-2.2-1.3s-1.1-1.3-1.4-2.1s-0.5-1.7-0.5-2.8v-10.8h3v10.7
		c0,0.4,0.1,0.9,0.2,1.3c0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,1.1,0.4,1.8,0.4c0.7,0,1.3-0.1,1.8-0.4
		c0.5-0.2,0.9-0.6,1.2-0.9c0.3-0.4,0.5-0.8,0.6-1.3c0.1-0.5,0.2-0.9,0.2-1.3v-10.7h3V701.4z M1221.5,707.6l-3.9-7.2h-2.2v7.2h-3v-17
		h5.9c0.8,0,1.6,0.1,2.4,0.2c0.8,0.2,1.4,0.4,2,0.8c0.6,0.4,1.1,0.9,1.4,1.5s0.5,1.4,0.5,2.4c0,1.2-0.3,2.3-1,3.1
		c-0.7,0.8-1.7,1.3-2.9,1.5l4.5,7.5H1221.5z M1233.8,700.4v7.3h-3v-7.3l-6.4-9.7h3.8l4.2,6.8l4.2-6.8h3.6L1233.8,700.4z
		 M1259.3,700.6h-7.5v7.1h-3v-17h11v2.7h-7.9v4.5h7.5V700.6z M1273.3,707.6l-1.6-3.9h-7.4l-1.5,3.9h-3.4l7.4-17h2.6l7.3,17H1273.3z
		 M1288.7,705c-0.3,0.7-0.8,1.2-1.4,1.7c-0.6,0.4-1.2,0.8-2,1c-0.8,0.2-1.5,0.3-2.4,0.3c-1.1,0-2.1-0.2-3-0.5
		c-1-0.4-1.8-0.9-2.5-1.8l2.3-2.2c0.4,0.6,0.9,1,1.5,1.3c0.6,0.3,1.2,0.5,1.9,0.5c0.4,0,0.7,0,1.1-0.1c0.4-0.1,0.7-0.2,1-0.4
		c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.3,0.3-0.6,0.3-1c0-0.6-0.2-1.1-0.6-1.4s-0.9-0.6-1.5-0.9c-0.6-0.2-1.2-0.5-1.9-0.7
		c-0.7-0.2-1.4-0.5-1.9-0.9c-0.6-0.4-1.1-0.8-1.5-1.4s-0.6-1.4-0.6-2.4c0-0.9,0.2-1.6,0.5-2.3c0.4-0.6,0.8-1.2,1.4-1.6
		s1.3-0.7,2-0.9c0.8-0.2,1.5-0.3,2.3-0.3c0.9,0,1.8,0.1,2.6,0.4c0.8,0.3,1.6,0.7,2.3,1.3l-2.2,2.3c-0.3-0.5-0.7-0.8-1.3-1
		s-1.1-0.3-1.7-0.3c-0.4,0-0.7,0-1,0.1c-0.3,0.1-0.6,0.2-0.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1
		c0,0.6,0.2,1,0.6,1.3s0.9,0.6,1.5,0.8c0.6,0.2,1.2,0.4,1.9,0.6c0.7,0.2,1.4,0.5,1.9,0.9c0.6,0.4,1.1,0.9,1.5,1.5
		c0.4,0.6,0.6,1.4,0.6,2.4C1289.2,703.6,1289.1,704.4,1288.7,705z M1306.4,707.6h-3v-7.5h-7.9v7.5h-3v-17h3v6.8h7.9v-6.8h3V707.6z
		 M1313.1,707.6h-3v-17h3V707.6z M1333.3,702.7c-0.4,1.1-1.1,2-1.9,2.8c-0.8,0.8-1.8,1.4-2.9,1.8s-2.3,0.7-3.6,0.7
		c-1.3,0-2.5-0.2-3.6-0.6c-1.1-0.4-2-1-2.8-1.8s-1.4-1.7-1.9-2.8s-0.7-2.3-0.7-3.6c0-1.4,0.2-2.6,0.7-3.7s1.1-2.1,1.9-2.8
		s1.7-1.4,2.8-1.8c1.1-0.4,2.3-0.6,3.6-0.6c1.3,0,2.5,0.2,3.6,0.6s2.1,1,2.9,1.8c0.8,0.8,1.4,1.7,1.9,2.8c0.4,1.1,0.7,2.3,0.7,3.7
		C1334,700.4,1333.7,701.7,1333.3,702.7z M1352.1,707.6h-3.8l-8.4-13h0v13h-3v-17h4l8.2,12.6h0v-12.6h3V707.6z M1281.7,545.9
		c0-22.2,9.8-42.8,26.6-57.5l-28.2-48.3h129v36.3l-14.1-8.1h-66.7l65.1,112.2l14.6-23.3h30.4L1408,606
		c16.3,27.1,27.7,47.2,27.7,47.2h-45l4.3-14.1l-10.3-19.4c-8.7,3.3-17.3,4.3-26.6,4.3C1315.3,623.5,1281.1,588.8,1281.7,545.9z
		 M1375,705.1c-0.4,0.6-0.9,1.1-1.5,1.5c-0.6,0.4-1.3,0.6-2.1,0.8c-0.8,0.2-1.6,0.2-2.4,0.2h-6.1v-17h6.6c0.6,0,1.3,0.1,1.9,0.2
		s1.2,0.4,1.7,0.7c0.5,0.3,0.9,0.8,1.2,1.3c0.3,0.5,0.5,1.2,0.5,2c0,1-0.3,1.8-0.8,2.4s-1.3,1.1-2.1,1.3v0c0.5,0.1,1,0.2,1.5,0.4
		s0.8,0.5,1.2,0.9s0.6,0.8,0.8,1.2c0.2,0.5,0.3,1,0.3,1.5C1375.6,703.7,1375.4,704.5,1375,705.1z M1387.8,707.6l-3.9-7.2h-2.2v7.2
		h-3v-17h5.9c0.8,0,1.6,0.1,2.4,0.2s1.4,0.4,2,0.8c0.6,0.4,1.1,0.9,1.4,1.5c0.4,0.6,0.5,1.4,0.5,2.4c0,1.2-0.3,2.3-1,3.1
		c-0.7,0.8-1.7,1.3-2.9,1.5l4.5,7.5H1387.8z M1405.7,707.6l-1.6-3.9h-7.4l-1.5,3.9h-3.4l7.4-17h2.6l7.3,17H1405.7z M1426.4,707.6
		h-3.8l-8.4-13h0v13h-3v-17h4l8.2,12.6h0v-12.6h3V707.6z M1444.7,702.9c-0.6,1.1-1.3,1.9-2.1,2.6s-1.8,1.2-2.9,1.6
		c-1.1,0.3-2.1,0.5-3.1,0.5h-6.3v-17h6.7c1.1,0,2.2,0.2,3.2,0.5c1,0.3,2,0.9,2.8,1.6c0.8,0.7,1.4,1.6,1.9,2.7
		c0.5,1.1,0.7,2.3,0.7,3.8C1445.5,700.6,1445.2,701.9,1444.7,702.9z M1450.3,444l-5.4-14.6V444h-2.7v-17.3h4.9l4.9,12.5l4.9-12.5
		h4.9V444h-3.9v-14.6l-5.4,14.6H1450.3z M1467.5,705.1c-0.4,0.6-0.9,1.1-1.5,1.5c-0.6,0.4-1.3,0.6-2.1,0.8c-0.8,0.2-1.6,0.2-2.4,0.2
		h-6.1v-17h6.6c0.6,0,1.3,0.1,1.9,0.2s1.2,0.4,1.7,0.7c0.5,0.3,0.9,0.8,1.2,1.3c0.3,0.5,0.5,1.2,0.5,2c0,1-0.3,1.8-0.8,2.4
		s-1.3,1.1-2.1,1.3v0c0.5,0.1,1,0.2,1.5,0.4s0.8,0.5,1.2,0.9s0.6,0.8,0.8,1.2c0.2,0.5,0.3,1,0.3,1.5
		C1468,703.7,1467.8,704.5,1467.5,705.1z M1487.5,702.7c-0.4,1.1-1.1,2-1.9,2.8s-1.8,1.4-2.9,1.8c-1.1,0.4-2.3,0.7-3.6,0.7
		c-1.3,0-2.5-0.2-3.6-0.6s-2-1-2.8-1.8s-1.4-1.7-1.9-2.8c-0.4-1.1-0.7-2.3-0.7-3.6c0-1.4,0.2-2.6,0.7-3.7c0.4-1.1,1.1-2.1,1.9-2.8
		s1.7-1.4,2.8-1.8s2.3-0.6,3.6-0.6c1.3,0,2.5,0.2,3.6,0.6c1.1,0.4,2.1,1,2.9,1.8s1.4,1.7,1.9,2.8c0.4,1.1,0.7,2.3,0.7,3.7
		C1488.1,700.4,1487.9,701.7,1487.5,702.7z M1500.2,707.6l-3.9-7.2h-2.2v7.2h-3v-17h5.9c0.8,0,1.6,0.1,2.4,0.2s1.4,0.4,2,0.8
		c0.6,0.4,1.1,0.9,1.4,1.5c0.4,0.6,0.5,1.4,0.5,2.4c0,1.2-0.3,2.3-1,3.1c-0.7,0.8-1.7,1.3-2.9,1.5l4.5,7.5H1500.2z M1521.4,707.6
		h-3.8l-8.4-13h0v13h-3v-17h4l8.2,12.6h0v-12.6h3V707.6z M1543.3,693.4h-7.9v4.5h7.5v2.7h-7.5v7.1h-3v-17h11V693.4z M1555.3,707.6
		l-3.9-7.2h-2.2v7.2h-3v-17h5.9c0.8,0,1.6,0.1,2.4,0.2s1.4,0.4,2,0.8c0.6,0.4,1.1,0.9,1.4,1.5c0.4,0.6,0.5,1.4,0.5,2.4
		c0,1.2-0.3,2.3-1,3.1c-0.7,0.8-1.7,1.3-2.9,1.5l4.5,7.5H1555.3z M1577.7,702.7c-0.4,1.1-1.1,2-1.9,2.8s-1.8,1.4-2.9,1.8
		c-1.1,0.4-2.3,0.7-3.6,0.7c-1.3,0-2.5-0.2-3.6-0.6s-2-1-2.8-1.8s-1.4-1.7-1.9-2.8c-0.4-1.1-0.7-2.3-0.7-3.6c0-1.4,0.2-2.6,0.7-3.7
		c0.4-1.1,1.1-2.1,1.9-2.8s1.7-1.4,2.8-1.8s2.3-0.6,3.6-0.6c1.3,0,2.5,0.2,3.6,0.6c1.1,0.4,2.1,1,2.9,1.8s1.4,1.7,1.9,2.8
		c0.4,1.1,0.7,2.3,0.7,3.7C1578.3,700.4,1578.1,701.7,1577.7,702.7z M1599.6,707.6h-2.9v-14.1h0l-5.2,14.1h-2.1l-5.2-14.1h0v14.1
		h-2.9v-17h4.6l4.5,11.9l4.6-11.9h4.6V707.6z M1627.3,707.6h-2.9l-3.9-12.9h0l-3.9,12.9h-2.8l-5-17h3.3l3.2,12.2h0l3.8-12.2h3
		l3.7,12.2h0l3.3-12.2h3.1L1627.3,707.6z M1645.8,707.6h-11.7v-17h11.3v2.7h-8.2v4.2h7.8v2.7h-7.8v4.6h8.7V707.6z M1660.8,705.1
		c-0.4,0.6-0.9,1.1-1.5,1.5c-0.6,0.4-1.3,0.6-2.1,0.8c-0.8,0.2-1.6,0.2-2.4,0.2h-6.1v-17h6.6c0.6,0,1.3,0.1,1.9,0.2s1.2,0.4,1.7,0.7
		c0.5,0.3,0.9,0.8,1.2,1.3c0.3,0.5,0.5,1.2,0.5,2c0,1-0.3,1.8-0.8,2.4s-1.3,1.1-2.1,1.3v0c0.5,0.1,1,0.2,1.5,0.4s0.8,0.5,1.2,0.9
		s0.6,0.8,0.8,1.2c0.2,0.5,0.3,1,0.3,1.5C1661.3,703.7,1661.1,704.5,1660.8,705.1z M1674.6,705.1c-0.3,0.6-0.7,1.1-1.3,1.6
		c-0.5,0.4-1.1,0.7-1.8,1c-0.7,0.2-1.4,0.3-2.2,0.3c-0.7,0-1.4-0.1-2-0.2c-0.6-0.2-1.2-0.4-1.7-0.7c-0.5-0.3-0.9-0.7-1.3-1.2
		c-0.4-0.5-0.6-1.1-0.8-1.8l3.1-0.8c0.2,0.7,0.5,1.2,0.9,1.6c0.4,0.4,1.1,0.6,1.8,0.6c0.4,0,0.7-0.1,1.1-0.2
		c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.5-0.5,0.6-0.8c0.2-0.3,0.2-0.7,0.2-1.1c0-0.5-0.1-1-0.3-1.3c-0.2-0.3-0.5-0.6-0.9-0.8
		c-0.4-0.2-0.8-0.3-1.2-0.4c-0.4-0.1-0.9-0.1-1.3-0.1h-0.7v-2.6h0.7c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.2,1.1-0.3
		c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.7,0.3-1.2c0-0.7-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.6-1.8-0.6c-0.6,0-1.1,0.2-1.5,0.5
		c-0.5,0.3-0.8,0.8-1,1.4l-3-0.8c0.2-0.6,0.5-1.2,0.8-1.6c0.4-0.5,0.8-0.8,1.2-1.1c0.5-0.3,1-0.5,1.6-0.7c0.6-0.1,1.2-0.2,1.8-0.2
		c0.7,0,1.4,0.1,2.1,0.3c0.7,0.2,1.2,0.5,1.7,0.9s0.9,0.8,1.2,1.4c0.3,0.6,0.4,1.2,0.4,2c0,0.9-0.3,1.7-0.8,2.4
		c-0.5,0.7-1.2,1.2-2.1,1.4v0c1.1,0.2,1.9,0.6,2.5,1.4c0.6,0.8,0.8,1.7,0.8,2.7C1675.1,703.7,1674.9,704.4,1674.6,705.1z
		 M1720.3,627.3l4.3-18.4l-7.6-22.2h-49.9l-7.6,22.2l4.3,18.4h-47.2l11.4-17.9l43.4-130.1l-6-12.5h52l-6,12.5l43.4,130.1l11.4,17.9
		H1720.3z M1830.9,627.3h-49.3l8.1-12.5V478.7l-8.1-12.5h49.3l-8.1,12.5v136.1L1830.9,627.3z M1992.5,627.3h-48.8l5.4-12.5
		L1890.5,514v100.8l7.6,12.5h-42.3l7.6-12.5V478.7l-7.6-12.5h53.7l-5.4,12.5l53.7,91.6v-91.6l-7.6-12.5h42.3l-7.6,12.5v136.1
		L1992.5,627.3z"
            />
            <path
              d="M1329.2,694.7c-0.5-0.6-1.1-1-1.8-1.3c-0.7-0.3-1.5-0.5-2.4-0.5s-1.7,0.2-2.4,0.5c-0.7,0.3-1.3,0.7-1.8,1.3
		s-0.9,1.2-1.2,1.9c-0.3,0.7-0.4,1.5-0.4,2.4c0,0.9,0.1,1.8,0.4,2.5c0.3,0.8,0.7,1.4,1.2,2c0.5,0.6,1.1,1,1.8,1.3
		c0.7,0.3,1.5,0.5,2.4,0.5s1.7-0.2,2.4-0.5c0.7-0.3,1.3-0.8,1.8-1.3c0.5-0.6,0.9-1.2,1.2-2c0.3-0.8,0.4-1.6,0.4-2.5
		c0-0.8-0.1-1.6-0.4-2.4C1330.1,695.9,1329.7,695.3,1329.2,694.7z"
            />
            <polygon points="1265.3,701.2 1270.6,701.2 1268,694.2 	" />
            <path
              d="M1221.1,696.8c0.2-0.3,0.3-0.7,0.3-1.3c0-0.5-0.1-0.9-0.3-1.2s-0.5-0.5-0.8-0.7c-0.3-0.2-0.7-0.3-1.1-0.3
		c-0.4,0-0.8-0.1-1.2-0.1h-2.6v4.6h2.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3C1220.6,697.3,1220.9,697.1,1221.1,696.8z"
            />
            <path
              d="M1554.8,696.8c0.2-0.3,0.3-0.7,0.3-1.3c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.7-0.3-1.1-0.3
		c-0.4,0-0.8-0.1-1.2-0.1h-2.6v4.6h2.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3C1554.3,697.3,1554.6,697.1,1554.8,696.8z"
            />
            <path
              d="M1464.1,700.7c-0.6-0.4-1.5-0.5-2.7-0.5h-2.9v4.9h2.8c0.4,0,0.8,0,1.2-0.1c0.4,0,0.8-0.2,1.2-0.3c0.4-0.2,0.7-0.4,0.9-0.8
		c0.2-0.3,0.4-0.8,0.4-1.3C1465,701.7,1464.7,701,1464.1,700.7z"
            />
            <path
              d="M1483.4,694.7c-0.5-0.6-1.1-1-1.8-1.3c-0.7-0.3-1.5-0.5-2.4-0.5c-0.9,0-1.7,0.2-2.4,0.5c-0.7,0.3-1.3,0.7-1.8,1.3
		s-0.9,1.2-1.2,1.9c-0.3,0.7-0.4,1.5-0.4,2.4c0,0.9,0.1,1.8,0.4,2.5c0.3,0.8,0.7,1.4,1.2,2c0.5,0.6,1.1,1,1.8,1.3
		c0.7,0.3,1.5,0.5,2.4,0.5c0.9,0,1.7-0.2,2.4-0.5c0.7-0.3,1.3-0.8,1.8-1.3c0.5-0.6,0.9-1.2,1.2-2c0.3-0.8,0.4-1.6,0.4-2.5
		c0-0.8-0.1-1.6-0.4-2.4C1484.3,695.9,1483.9,695.3,1483.4,694.7z"
            />
            <path
              d="M1499.7,696.8c0.2-0.3,0.3-0.7,0.3-1.3c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.7-0.3-1.1-0.3
		c-0.4,0-0.8-0.1-1.2-0.1h-2.6v4.6h2.4c0.4,0,0.9,0,1.3-0.1c0.4,0,0.8-0.1,1.2-0.3C1499.2,697.3,1499.5,697.1,1499.7,696.8z"
            />
            <path
              d="M1573.6,694.7c-0.5-0.6-1.1-1-1.8-1.3c-0.7-0.3-1.5-0.5-2.4-0.5c-0.9,0-1.7,0.2-2.4,0.5c-0.7,0.3-1.3,0.7-1.8,1.3
		s-0.9,1.2-1.2,1.9c-0.3,0.7-0.4,1.5-0.4,2.4c0,0.9,0.1,1.8,0.4,2.5c0.3,0.8,0.7,1.4,1.2,2c0.5,0.6,1.1,1,1.8,1.3
		c0.7,0.3,1.5,0.5,2.4,0.5c0.9,0,1.7-0.2,2.4-0.5c0.7-0.3,1.3-0.8,1.8-1.3c0.5-0.6,0.9-1.2,1.2-2c0.3-0.8,0.4-1.6,0.4-2.5
		c0-0.8-0.1-1.6-0.4-2.4C1574.5,695.9,1574.1,695.3,1573.6,694.7z"
            />
          </g>
        </svg>
        {/* <svg
          id="logoDesktop"
          preserveAspectRatio="xMidYMid slice"
          fill="black"
          fillOpacity="1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 318.6 199.2"
          style={{ enableBackground: "new 0 0 318.6 199.2" }}
        >
          <g>
            <polygon points="222.5,93.2 219.1,103.8 222.5,103.8 225.9,103.8 222.5,93.2 	" />
            <path d="M200.1,94.8c0-2.7-1.5-4-4.8-4h-6.5v8h6.5C198.5,98.8,200.1,97.5,200.1,94.8z" />
            <path d="M151.9,100.9c0,4.9,4,8.9,8.9,9c0.7,0,1.5-0.1,2.2-0.2l-8.7-14.9C152.7,96.4,151.9,98.6,151.9,100.9z" />
            <path
              d="M99,128.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0h-0.5v0.8h0.4c0.1,0,0.2,0,0.2,0
          		c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C99,128.5,99,128.4,99,128.3z"
            />
            <path
              d="M140.7,128.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
          		c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2
          		c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.5
          		c0-0.2,0-0.3-0.1-0.4C140.8,128.4,140.8,128.3,140.7,128.2z"
            />
            <path
              d="M151.7,128.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0h-0.5v0.8h0.4
          		c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C151.7,128.5,151.7,128.4,151.7,128.3z"
            />
            <path
              d="M169.7,128.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
          		c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2
          		c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.5
          		c0-0.2,0-0.3-0.1-0.4C169.8,128.4,169.8,128.3,169.7,128.2z"
            />
            <path
              d="M229.9,128.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0h-0.5v0.8h0.4
          		c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C229.9,128.5,229.9,128.4,229.9,128.3z"
            />
            <path
              d="M0,0v199.2h318.6V0H0z M173.2,78.7h2.5l0,0.5h-1v2.7h-0.6v-2.7h-1V78.7z M95.4,86h8.2l-1.4,3.2v21h12.4l2.3-1.4l0,6.8H95.3
          		l1.5-2.3V89.2L95.4,86z M63.5,93.7l-3-2.2c-5.7-1.2-9.7-0.2-12,2.4c-1.6,1.8-2.1,4.5-2.1,6.9s0.5,5.1,2.1,6.9
          		c2.3,2.6,6.3,3.6,12,2.4l3-2.2v7.2c-2.5,0.4-5.1,0.6-7.7,0.5c-10.5,0-15-6.8-15-14.8c0-8,4.6-14.8,15-14.8c2.6,0,5.1,0.1,7.7,0.5
          		V93.7z M85.5,127.9h-1v2.6H84v-2.6h-1v-0.5h2.5V127.9z M88.6,130.5H88v-1.4h-1.5v1.4H86v-3.1h0.6v1.2H88v-1.2h0.6V130.5z
          		 M91.5,130.5h-2.2v-3.1h2.1v0.5h-1.5v0.8h1.4v0.5h-1.4v0.8h1.6V130.5z M91.2,89.3v15.2c0,5.2-0.6,7-2.7,9.1s-5.4,2.9-9,2.9
          		s-6.5-0.9-8.5-2.9s-2.7-3.9-2.7-9.1V89.3L67,86h8.1l-1.3,3.3v14.9c0,3.8,0.2,4.8,1.2,5.8s2.6,1.5,4.8,1.5s4-0.6,4.9-1.7s1-2,1-5.6
          		V89.3L84.4,86h8.2L91.2,89.3z M95.5,127.9H94v0.8h1.4v0.5H94v1.3h-0.6v-3.1h2V127.9z M96.6,130.5H96v-3.1h0.6V130.5z M99,130.5
          		l-0.7-1.3h-0.4v1.3h-0.6v-3.1h1.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
          		c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.2-0.5,0.3l0.8,1.4H99z M102,130.1c-0.1,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
          		s-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3l0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.1
          		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
          		c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.4
          		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.2s0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.1,0.4,0.2l-0.4,0.4
          		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
          		c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1s0.2,0.1,0.4,0.2s0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
          		C102.1,129.8,102.1,129.9,102,130.1z M104.9,127.9h-1v2.6h-0.6v-2.6h-1v-0.5h2.5V127.9z M108.6,130.5h-1.9v-3.1h0.6v2.6h1.3V130.5z
          		 M111.6,129.4c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1s-0.3,0-0.5-0.1
          		c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.1-0.3-0.1-0.5v-2h0.6v2c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0.1,0.2
          		c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2
          		v-2h0.6V129.4z M114.3,130.5l-0.8-1.3l-0.8,1.3H112l1.1-1.6l-1-1.5h0.7l0.7,1.2l0.7-1.2h0.7l-1,1.5l1.1,1.6H114.3z M117.9,129.4
          		c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5-0.1
          		c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.1-0.3-0.1-0.5v-2h0.6v2c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0.1,0.2
          		c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2
          		c0-0.1,0-0.2,0-0.2v-2h0.6V129.4z M120.4,130.5l-0.7-1.3h-0.4v1.3h-0.6v-3.1h1.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1
          		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.2-0.5,0.3l0.8,1.4H120.4z M122.7,129.2
          		v1.3h-0.6v-1.3l-1.2-1.8h0.7l0.8,1.2l0.8-1.2h0.7L122.7,129.2z M127.5,129.2h-1.4v1.3h-0.6v-3.1h2v0.5h-1.5v0.8h1.4V129.2z
          		 M130.1,130.5l-0.3-0.7h-1.4l-0.3,0.7h-0.6l1.4-3.1h0.5l1.3,3.1H130.1z M133,130.1c-0.1,0.1-0.1,0.2-0.3,0.3
          		c-0.1,0.1-0.2,0.1-0.4,0.2s-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3l0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.2
          		c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3
          		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4
          		c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.2s0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.5,0.1
          		c0.2,0.1,0.3,0.1,0.4,0.2l-0.4,0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1
          		c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1s0.2,0.1,0.4,0.2
          		s0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4C133.1,129.8,133.1,129.9,133,130.1z M136.4,130.5h-0.6v-1.4h-1.5v1.4h-0.6v-3.1h0.6v1.2
          		h1.5v-1.2h0.6V130.5z M129.2,91.4v21.9l1.3,2.3h-8.1l1.3-2.3V91.4h-7.7l-2.2,1.4V86h25.2v6.8l-2.2-1.4H129.2z M137.7,130.5h-0.6
          		v-3.1h0.6V130.5z M141.4,129.6c-0.1,0.2-0.2,0.4-0.3,0.5s-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
          		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.3-0.5
          		c0.1-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.5,0.3s0.3,0.3,0.3,0.5
          		c0.1,0.2,0.1,0.4,0.1,0.7C141.6,129.2,141.5,129.4,141.4,129.6z M145,130.5h-0.7l-1.5-2.4h0v2.4h-0.6v-3.1h0.7l1.5,2.3h0v-2.3h0.6
          		V130.5z M149.3,130.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0h-1.1v-3.1h1.2c0.1,0,0.2,0,0.3,0
          		c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2v0
          		c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3C149.4,129.8,149.4,130,149.3,130.1z
          		 M151.7,130.5l-0.7-1.3h-0.4v1.3H150v-3.1h1.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3
          		c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.2-0.5,0.3l0.8,1.4H151.7z M155.1,130.5l-0.3-0.7h-1.4l-0.3,0.7
          		h-0.6l1.4-3.1h0.5l1.3,3.1H155.1z M158.9,130.5h-0.7l-1.5-2.4h0v2.4h-0.6v-3.1h0.7l1.5,2.3h0v-2.3h0.6V130.5z M162.4,129.7
          		c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1h-1.2v-3.1h1.2c0.2,0,0.4,0,0.6,0.1
          		c0.2,0.1,0.4,0.2,0.5,0.3s0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7C162.5,129.2,162.5,129.5,162.4,129.7z M166.7,130.1
          		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0h-1.1v-3.1h1.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1
          		c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2v0c0.1,0,0.2,0,0.3,0.1
          		c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3C166.8,129.8,166.7,130,166.7,130.1z M165.7,114.3
          		c-1.6,0.6-3.2,0.8-4.9,0.8c-7.9-0.1-14.2-6.5-14.1-14.4c0-4.1,1.8-7.9,4.9-10.6l-5.2-8.9h23.8v6.7l-2.6-1.5h-12.3l12,20.7l2.7-4.3
          		h5.6l-5.6,9c3,5,5.1,8.7,5.1,8.7h0v0h-8.3l0.8-2.6L165.7,114.3z M170.4,129.6c-0.1,0.2-0.2,0.4-0.3,0.5s-0.3,0.3-0.5,0.3
          		c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7
          		c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0,0.5,0,0.7,0.1
          		c0.2,0.1,0.4,0.2,0.5,0.3s0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.7C170.6,129.2,170.5,129.4,170.4,129.6z M172.8,130.5l-0.7-1.3
          		h-0.4v1.3h-0.6v-3.1h1.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
          		c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.2-0.5,0.3l0.8,1.4H172.8z M176.8,130.5h-0.7l-1.5-2.4h0v2.4H174v-3.1h0.7l1.5,2.3h0v-2.3
          		h0.6V130.5z M179.5,130.5h-0.6v-3.1h0.6V130.5z M179.2,81.9v-2.7l-1,2.7h-0.4l-1-2.7v2.7h-0.5v-3.2h0.9l0.9,2.3l0.9-2.3h0.9l0,3.2
          		H179.2z M183,130.5h-0.7l-1.5-2.4h0v2.4h-0.6v-3.1h0.7l1.5,2.3h0v-2.3h0.6V130.5z M187.3,127.9h-1v2.6h-0.6v-2.6h-1v-0.5h2.5V127.9
          		z M190.3,130.5h-0.6v-1.4h-1.5v1.4h-0.6v-3.1h0.6v1.2h1.5v-1.2h0.6V130.5z M193.3,130.5h-2.2v-3.1h2.1v0.5h-1.5v0.8h1.4v0.5h-1.4
          		v0.8h1.6V130.5z M188.8,103.5v9.8l1.4,2.3h-8.4l1.4-2.3V88.3l-1.4-2.3h13.3c4.2,0,6.4,0.7,8.2,2.5c1.6,1.7,2.4,4,2.4,6.3
          		c0.2,3.9-2.5,7.3-6.3,8.1l4.8,9.3l3,3.5l-9.4,0.1l0.8-2.7l-4.8-9.4H188.8z M197.4,130.5c-0.2,0.1-0.4,0.1-0.7,0.1
          		c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0-0.5,0.1-0.7
          		c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4l-0.4,0.3
          		c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.4
          		c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1
          		c0.1,0,0.2,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3l0.5,0.3C197.7,130.3,197.6,130.4,197.4,130.5z M200,130.5l-0.7-1.3h-0.4v1.3h-0.6
          		v-3.1h1.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
          		c-0.1,0.2-0.3,0.2-0.5,0.3l0.8,1.4H200z M202.3,129.2v1.3h-0.6v-1.3l-1.2-1.8h0.7l0.8,1.2l0.8-1.2h0.7L202.3,129.2z M205.9,128.8
          		c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.5,0h-0.4v1.3h-0.6v-3.1h1.1c0.2,0,0.3,0,0.4,0
          		c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4C206,128.5,206,128.7,205.9,128.8z M208.8,127.9h-1v2.6
          		h-0.6v-2.6h-1v-0.5h2.5V127.9z M212.2,129.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
          		c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0-0.5,0.1-0.7
          		c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.5,0.3
          		c0.1,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.7C212.4,129.2,212.3,129.4,212.2,129.6z M216.8,129.4c0,0.2,0,0.4-0.1,0.5
          		c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.3-0.1-0.4-0.2
          		c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.1-0.3-0.1-0.5v-2h0.6v2c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2
          		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2v-2h0.6V129.4z
          		 M220.4,130.5h-0.7l-1.5-2.4h0v2.4h-0.6v-3.1h0.7l1.5,2.3h0v-2.3h0.6V130.5z M221.7,130.5h-0.6v-3.1h0.6V130.5z M223.8,130.5h-0.5
          		l-1.2-3.1h0.6l0.9,2.4l0.9-2.4h0.6L223.8,130.5z M227.6,130.5h-2.2v-3.1h2.1v0.5H226v0.8h1.4v0.5H226v0.8h1.6V130.5z M229.9,130.5
          		l-0.7-1.3h-0.4v1.3h-0.6v-3.1h1.1c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
          		c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.2-0.5,0.3l0.8,1.4H229.9z M232.9,130.1c-0.1,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.2
          		s-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3l0.4-0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.1
          		c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
          		c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.2,0-0.3,0.1-0.4
          		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.2s0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.1,0.4,0.2l-0.4,0.4
          		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
          		c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1s0.2,0.1,0.4,0.2s0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4
          		C233,129.8,233,129.9,232.9,130.1z M235.8,130.5h-2.2v-3.1h2.1v0.5h-1.5v0.8h1.4v0.5h-1.4v0.8h1.6V130.5z M227.6,115.7l0.8-3.4
          		l-1.4-4.1h-9.2l-1.4,4.1l0.8,3.4h-8.7l2.1-3.3l8-24l-1.1-2.3h9.6l-1.1,2.3l8,24l2.1,3.3H227.6z M248,115.7h-9.1l1.5-2.3V88.3
          		l-1.5-2.3h9.1l-1.5,2.3v25.1L248,115.7z M268.8,115.7l1-2.3L259,94.8v18.6l1.4,2.3h-7.8l1.4-2.3V88.3l-1.4-2.3h9.9l-1,2.3l9.9,16.9
          		V88.3L270,86h7.8l-1.4,2.3v25.1l1.4,2.3H268.8z"
            />
            <path
              d="M211.5,128.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
          		c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2
          		c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.1-0.3,0.1-0.5
          		c0-0.2,0-0.3-0.1-0.4C211.6,128.4,211.6,128.3,211.5,128.2z"
            />
            <path
              d="M205.2,128c-0.1,0-0.1-0.1-0.2-0.1s-0.2,0-0.2,0h-0.4v0.9h0.3c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2-0.1
          		c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2C205.4,128,205.3,128,205.2,128z"
            />
            <path
              d="M200,128.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0h-0.5v0.8h0.4c0.1,0,0.2,0,0.2,0
          		c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C200,128.5,200,128.4,200,128.3z"
            />
            <path
              d="M172.8,128.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0h-0.5v0.8h0.4
          		c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C172.8,128.5,172.8,128.4,172.8,128.3z"
            />
            <path
              d="M166,128.6c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.5-0.1H165v0.8h0.5
          		C165.7,128.7,165.9,128.7,166,128.6z"
            />
            <path
              d="M165.6,129.2H165v0.9h0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2
          		c0-0.2-0.1-0.3-0.2-0.3C166,129.2,165.8,129.2,165.6,129.2z"
            />
            <path
              d="M161.6,128.2c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1h-0.5v2.1h0.4c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2
          		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.4-0.1-0.5S161.7,128.2,161.6,128.2z"
            />
            <polygon points="153.6,129.4 154.6,129.4 154.1,128.1 	" />
            <path
              d="M148.6,128.6c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.5-0.1h-0.5v0.8h0.5
          		C148.3,128.7,148.5,128.7,148.6,128.6z"
            />
            <path
              d="M148.2,129.2h-0.5v0.9h0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2
          		c0-0.2-0.1-0.3-0.2-0.3C148.6,129.2,148.4,129.2,148.2,129.2z"
            />
            <polygon points="128.7,129.4 129.6,129.4 129.2,128.1 	" />
            <path
              d="M120.3,128.3c0-0.1,0-0.2-0.1-0.2s-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0h-0.5v0.8h0.4
          		c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1C120.3,128.5,120.3,128.4,120.3,128.3z"
            />
          </g>
        </svg> */}
      </Plx>
      <div style={{ height: `${beigeHeight}%` }} className="intro_beige" />
    </div>
  );
};

export default Intro;
